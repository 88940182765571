
import { createSignal, For, JSX, Show, splitProps } from "solid-js";
import { createStore, } from "solid-js/store";
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { SelectChangeEvent } from "@suid/material/Select";
import { isExtraSmall } from "../../../utils/sizeUtil"

import {
    Typography, Button, Grid, Select, MenuItem, ListItemText,
    IconButton, Stack, Card, Divider, Box, 
} from "@suid/material";
 
import { AddIcon, CloseIcon } from '../icons';
import { AvatarChipWithIcon } from '../SmallComponents/SmallChips';
import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { UploadSocialMediaLink, ShowSocialMedia } from '../SmallComponents/SmallSocialMedia';

import { AccountMenu } from '../AppMenuBar/AppBarMenu';
import { ShowHeading } from '../SmallComponents/SmallHeader'
import { Tabs, Tab } from '../SmallComponents/SmallTabs';

import { ContractRates } from '../MediumComponents/MediumContractRatesNew'
import { TeamMembersList } from '../SmallComponents/SmallFieldTeamMembers';
import { FieldEmail } from "../SmallComponents/SmallFieldEmail";
import { FieldLanguageList } from '../SmallComponents/SmallFieldLanguageList';
import { FieldTextValue } from "../SmallComponents/SmallFieldText";
import { FieldWorkModel } from "../SmallComponents/SmallFieldWorkModel";
import { FieldSelectList } from "../SmallComponents/SmallFieldSelectList";
import { FieldAddress } from "../SmallComponents/SmallFieldAddress"; 
import { getText } from '../SmallComponents/Translate'


interface CustomerJobEditComponentProps {
    header: string;
}
/*
interface CustomerJobEditComponentProps {
    header: string;
    jobName?: string;
    jobDescription?: string;
    showForCandidates?: string;
    rateValue?: string;
    workModel?: string;
    remoteWork?: string;

    setWorkModel: (newValue: string) => void;
    setHours: (newValue: string) => void;
    setAllocation: (newValue: string) => void;
    setOnSiteAllocation: (newValue: string) => void;
    setRemoteAllocation: (newValue: string) => void;

    setRateModel: (newValue: string) => void;
   // children: JSX.Element;
}
*/ 
const CustomerJobEditComponent = (props: CustomerJobEditComponentProps) =>{
    //  const [local] = splitProps(props, ['children'])

    const [state, setState] = createStore({
        workModel: 'Remote',
        remoteWork: '3',
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });


    const handleInputChange = (key: string, value: string) => {
        /*
        try {
            const setValue = props[key as keyof CustomerJobEditProps];

            if (typeof setValue === "function") {
                const setValue = props[key as keyof CustomerJobEditProps] as ((newValue: string) => void) | undefined;
                if (setValue) {
                    setValue(value);

                }
            } else {
                const setValue = props[key as keyof CustomerJobEditProps] as ((newValue: string) => void) | undefined;
                if (setValue) {
                    setValue(value);
                }

                //  throw new Error(`Tried to set prop "${key}", but it's not a function. Got: ${setValue}`);
            }
        } catch (error) {

        }
        */
    };

    const [activeTab, setActiveTab] = createSignal<number>(0)

    const [open, setOpen] = createSignal(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
       
        window.history.go(-1); return false;
    }

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };


    const [saving, setSaving] = createSignal(false);


    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const [socialMedia, setSocialMedia] = createSignal("presentation");
    const handleSocialMedia = (value: string) => {
        setSocialMedia(value);
    }
    const [userRoles, setUserRoles] = createSignal(['Administrator', 'Manager', 'Viewer', 'User', 'Recruiter', 'Legal', 'Billing'])
    const [activeUserRole, setActiveUserRole] = createSignal('User')

    const [jobContactRoles, setJobContactRoles] = createSignal(['Recruiter', 'Director', 'Project Manager', 'Manager', 'HR', 'Assistant', 'Procurment', 'Legal', 'Billing'])
    const [activeJobContactRole, setActiveJobContactRole] = createSignal('Recruiter')



    const [userName, setUserName] = createSignal('newUser@findasap.team');
    const [firstName, setFirstName] = createSignal('');
    const [lastName, setLastName] = createSignal('');
    const [mobile, setMobile] = createSignal('');

    const handleChangeUserRole = (event: SelectChangeEvent) => {

        const newValue = event.target.value;
        setActiveUserRole(newValue)
    }; 


    const txtDetails = getText("jobpage", "details")
    const txtJobdescription = getText("jobpage", "jobdescription")
    const txtContacts = getText("jobpage", "contacts")
    const txtRoles = getText("jobpage", "roles")
    const txtRates = getText("jobpage", "rates")
    const txtJobname = getText("jobpage", "jobname")
    const txtOnsiteaddress = getText("jobpage", "onsiteaddress")
    const txtOthers = getText("default", "others")
    const txtMandatorylanguages = getText("jobpage", "mandatorylanguage")
    const txtJobvisibel = getText("jobpage", "jobvisible")
    const txtWorkmodel = getText("jobpage", "workmodel")
    const txtSocial = getText("jobpage", "social")
    const txtUrlsocial = getText("jobpage", "urlsocial")
    const txtUpload = getText("default", "upload")
    const txtJobcompany = getText("jobpage", "jobcompany")
    const txtAboutus = getText("jobpage", "aboutus")
    const txtWhatoffer = getText("jobpage", "whatweoffer")
    const txtTechcompetence = getText("jobpage", "techcompetence")
    const txtPersoncompetence = getText("jobpage", "personcompetence")
    const txtAddperson = getText("jobpage", "addperson")
    const txtAdduser = getText("jobpage", "adduser")
    const txtAddAnotherUser = getText("jobpage", "addanotheruser")
    const txtInvite = getText("jobpage", "invite")
    const txtEmail = getText("contact", "email")
    const txtFirstname = getText("contact", "firstname")
    const txtLastname = getText("contact", "lastname")
    const txtSendInvite = getText("jobpage", "sendinvite")
    const txtTeamMembers = getText("jobpage", "teammembers")

    //<ShowHeading pxValue={4} >{props.children}</ShowHeading>
    // <AccountMenu />

    try {
        return (

            <>
                <AccountMenu />

                <ShowHeading pxValue={4} >{props.header}</ShowHeading>

                <Divider />

                {/*   */}

                <Card sx={{ margin: 1, display: 'flexgrow' }}>
                    <Stack direction="column" alignItems="top" justifyContent="space-between" sx={{ px: 2, display: 'flex' }} >
                        {/* Addition navigation options for the customer */}
                        <Box sx={{ display: 'flex', bgcolor: 'background.default'[200] }} >
                            <IconButton onClick={handleClose} > <CloseIcon /> </IconButton>

                            <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>
                                <Tab>{txtDetails()}</Tab>
                                <Tab>{txtJobdescription()}</Tab>
                                <Tab>{txtContacts()}</Tab>
                                <Tab>{txtRoles()}</Tab>
                                <Tab>{txtRates()}</Tab>

                            </Tabs>

                        </Box> 
                        <Grid container spacing={1} style={{ width: '100%' }}>

                            <Grid item xs={12} md={12}>

                                <Divider />

                                {/* Candidates current assignment for the customer */}
                                <Card >
                                    <Stack direction="column" alignItems="left" sx={{ px: 2, py: 1, bgcolor: 'background.default' }} >

                                        {/* Details */}
                                        <Show
                                            when={activeTab() === 0}
                                        >
                                            <Grid container  >

                                                <Grid item xs={12} md={6} spacing={2}  >
                                                    <Stack direction="column" spacing={3} alignItems="left" margin={1}>

                                                        <FieldTextValue
                                                            id="jobname" label={txtJobname()} textValue="HANA S/4 Upgrade"
                                                            valueOnChange={value => handleInputChange("JobName", value)}
                                                        />
                                                        <FieldTextValue
                                                            id="jobdescription" label={txtJobdescription()} multiline={true} textValue="The best SAP S/4 project in Europe in 2022. Come and join."
                                                            valueOnChange={value => handleInputChange("JobDescription", value)}
                                                        />
                                                        <FieldAddress type="edit"
                                                            id="adresse" label={txtOnsiteaddress()} startValue="16 Glenmaura National Blvd, Scranton, PA 18507, United States"
                                                        />

                                                    </Stack>
                                                </Grid>
 

                                                <Grid item xs={12} md={6}>



                                                    <CardWithMinimize header={txtOthers() as string} type="list" defaultOpen={true}>
                                                        <Stack margin={1}>
                                                            <FieldLanguageList id="mandatoryLanguages" label={txtMandatorylanguages() as string} />
                                                            
                                                        </Stack>
                                                    </CardWithMinimize>




                                                    <CardWithMinimize header={txtWorkmodel() as string} type="list" defaultOpen={true}>
                                                        <FieldWorkModel
                                                            workModel={state.workModel}
                                                            remoteWork={state.remoteWork}
                                                            setWorkModel={value => handleInputChange("setWorkModel", value)}
                                                            setRemoteWork={value => handleInputChange("setRemoteWork", value)}
                                                        />
                                                    </CardWithMinimize>



                                                    <CardWithMinimize header={txtSocial() as string} type="list" defaultOpen={true} >

                                                        <Stack direction="row" spacing={2} margin={2}>
                                                            <UploadSocialMediaLink />
                                                             
                                                            {/*
                                                            <FormControl>
                                                                <label for="contained-button-file">
                                                                    <Input
                                                                        accept="image/*"
                                                                        id="contained-button-file"
                                                                        multiple
                                                                        type="file"
                                                                    />
                                                                    <Button variant="contained" component="span">
                                                                        {txtUpload()}
                                                                    </Button>
                                                                </label>
                                                            </FormControl>
                                                            */}
                                                        </Stack>
                                                        <Show
                                                                when={isExtraSmall()}
                                                            >
                                                                <FieldTextValue
                                                                    id="linktwitter" label={txtUrlsocial()} width={300} textValue=""
                                                                    valueOnChange={value => handleInputChange("linkTwitter", value)}
                                                                />
                                                            </Show>
                                                    </CardWithMinimize>

                                                </Grid>

                                            </Grid>
                                        </Show>


                                        {/* Description */}
                                        <Show
                                            when={activeTab() === 1}
                                        >
                                            <Typography variant="subtitle1">{txtJobcompany()}</Typography>

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>


                                                <Grid container spacing={2}  direction={isExtraSmall() ? "column" : "row"} style={{ width: '100%' }}>

                                                    <Grid item xs={12} >

                                                        <FieldTextValue
                                                            id="aboutus" label={txtAboutus()} textValue="" multiline={true} fullWidth={true} maxRows={10}
                                                            valueOnChange={value => handleInputChange("JobDescription", value)}
                                                        />

                                                    </Grid>
                                                    <Grid item xs={12}   >
                                                        <FieldTextValue
                                                            id="whatwecanoffer" label={txtWhatoffer()} textValue="" multiline={true} fullWidth={true} maxRows={10}
                                                            valueOnChange={value => handleInputChange("JobDescription", value)}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={12} >
                                                        <FieldTextValue
                                                            id="aboutus" label={txtTechcompetence()} textValue="" multiline={true} fullWidth={true} maxRows={10}
                                                            valueOnChange={value => handleInputChange("JobDescription", value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <FieldTextValue
                                                            id="whatwecanoffer" label={txtPersoncompetence()} textValue="" multiline={true} fullWidth={true} maxRows={10}
                                                            valueOnChange={value => handleInputChange("JobDescription", value)}
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </Box>

                                        </Show>


                                        {/* Contacts */}
                                        <Show
                                            when={activeTab() === 2}
                                        >

                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                            }}>
                                                <Grid container spacing={1} style={{ width: '100%' }}>

                                                    <Grid item xs={12} md={6}>

                                                        <CardWithMinimize header={txtContacts() as string} type="list" defaultOpen={true}>

                                                            <Stack direction="column" spacing={2} margin={2}>
                                                                <AvatarChipWithIcon url={getDummyAvatar()} >Terry Nelson, Project Manager</AvatarChipWithIcon>
                                                                <AvatarChipWithIcon url={getDummyAvatar()} >Benny Morison, Recruitment Manager</AvatarChipWithIcon>
                                                                <AvatarChipWithIcon url={getDummyAvatar()} >Henrik Henriksen, Assistant, (Invite Sent)</AvatarChipWithIcon>
                                                            </Stack>


                                                            <Typography sx={{ px: 2 }} variant="subtitle1">{txtAddperson()}</Typography>


                                                            <Stack direction={isExtraSmall() ? "column" : "row"} spacing={2} margin={2}>
                                                                <TeamMembersList id="teamMemberId" label={txtTeamMembers() as string} />

                                                                <Select
                                                                    id="addAnotherExternalUserId"
                                                                    value={activeJobContactRole()}
                                                                    defaultValue={activeJobContactRole()}
                                                                    label="Job Contact Role"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onChange={handleChangeUserRole}
                                                                >
                                                                    {jobContactRoles().map((option, index) => (
                                                                        <MenuItem
                                                                            value={option}
                                                                        >
                                                                            <ListItemText>
                                                                                {option}
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    ))}

                                                                </Select>

                                                                <Button variant="contained" component="span" startIcon={<AddIcon />}>
                                                                    {txtAdduser()}
                                                                </Button>

                                                            </Stack>
                                                        </CardWithMinimize>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CardWithMinimize header={txtAddAnotherUser() as string} type="list" defaultOpen={true}>
                                                            <Stack direction="column" alignItems="left" margin={2} spacing={2}>

                                                                <Typography variant="subtitle1">{txtInvite()}</Typography>

                                                                <FieldEmail
                                                                    id="inviteEmail" label={txtEmail()} textValue="" width={230}
                                                                    valueOnChange={value => setMobile(value)}
                                                                />

                                                                <Stack direction={isExtraSmall() ? "column" : "row"} alignItems="left" spacing={1}  >


                                                                    <FieldTextValue
                                                                        id="firstName" label={txtFirstname()} textValue={txtFirstname() as string} width={230}
                                                                        valueOnChange={value => setFirstName(value)}
                                                                    />
                                                                    <FieldTextValue
                                                                        id="lastName" label={txtLastname()} textValue={txtLastname() as string} width={230}
                                                                        valueOnChange={value => setLastName(value)}
                                                                    />
                                                                </Stack>

                                                                <Select
                                                                    value={activeUserRole()}
                                                                    id="invitedUserRoleId"
                                                                    defaultValue={activeUserRole()}
                                                                    label="Role"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    onChange={handleChangeUserRole}
                                                                >
                                                                    {userRoles().map((option, index) => (
                                                                        <MenuItem
                                                                            value={option}
                                                                        >
                                                                            <ListItemText>
                                                                                {option}
                                                                            </ListItemText>
                                                                        </MenuItem>
                                                                    ))}

                                                                </Select>

                                                                <Button variant="outlined"  >{txtSendInvite()}</Button>
                                                            </Stack>
                                                        </CardWithMinimize>


                                                    </Grid>
                                                </Grid>
                                            </Box>

                                        </Show>


                                        {/* Roles */}
                                        <Show
                                            when={activeTab() === 3}
                                        >

                                            <Grid container spacing={2}>

                                                <Grid item xs={12} md={12}>

                                                    <CardWithMinimize header="Roles" type="list" defaultOpen={true} >

                                                        <Typography variant="subtitle1">Select a number of roles for the job. You can always add additional roles </Typography>

                                                        <FieldSelectList saving={saving()} id="addJobRolesId" label="Roles" type="role" />

                                                    </CardWithMinimize>

                                                </Grid>
                                            </Grid>
                                        </Show>


                                        {/* Rates */}
                                        <Show
                                            when={activeTab() === 4}
                                        >

                                            <Grid container spacing={2}>

                                                <Grid item xs={12} md={12}>


                                                    <CardWithMinimize header="Rates & Expenses" type="list" defaultOpen={true}>

                                                        {/* Contract Rates - both simple and advanced*/}
                                                        <ContractRates
                                                            calendarDays={state.calendarDays}
                                                            showAllocation={false}    
                                                            numberOfHours={String(state.totalHours)}
                                                            setHours={(newValue) => handleChange('totalHours', newValue)}
                                                            numberOfOnSiteHours={state.allocationOnSite}
                                                            numberOfRemoteHours={state.allocationRemote}
                                                            setOnSiteAllocation={(newValue) => handleChange('allocationOnSite', newValue)}
                                                            setRemoteAllocation={(newValue) => handleChange('allocationRemote', newValue)}

                                                            allocation={state.allocation}
                                                            setAllocation={(newValue) => handleChange('allocation', newValue)}
                                                            allocationUnits={state.allocationUnits}
                                                            setAllocationUnits={(newValue) => handleChange('allocationUnits', newValue)}
                                                            allocationUnitsPr={state.allocationUnitsPr}
                                                            setAllocationUnitsPr={(newValue) => handleChange('allocationUnitsPr', newValue)}

                                                            rateModel={state.rateModel}
                                                            setRateModel={(newValue) => handleChange('rateModel', newValue)}
                                                            expenseModel={state.expenseModel}
                                                            setExpenseModel={(newValue) => handleChange('expenseModel', newValue)}

                                                            currency={state.currency}
                                                            setCurrency={(newValue) => handleChange('currency', newValue)}

                                                            ratePer={state.ratePer}
                                                            setRatePer={(newValue) => handleChange('ratePer', newValue)}

                                                            rateValue={state.rateValue.toString()}
                                                            remoteRateValue={state.remoteRateValue.toString()}
                                                            onSiteRateValue={state.onSiteRateValue.toString()}
                                                            setRateValue={(newValue) => handleChange('rateValue', newValue)}
                                                            setRemoteRateValue={(newValue) => handleChange('remoteRateValue', newValue)}
                                                            setOnSiteRateValue={(newValue) => handleChange('onSiteRateValue', newValue)}

                                                            expenseValue={state.expenseValue.toString()}
                                                            remoteExpenseValue={state.remoteExpenseValue.toString()}
                                                            onSiteExpenseValue={state.onSiteExpenseValue.toString()}
                                                            setExpenseValue={(newValue) => handleChange('expenseValue', newValue)}
                                                            setRemoteExpenseValue={(newValue) => handleChange('remoteExpenseValue', newValue)}
                                                            setOnSiteExpenseValue={(newValue) => handleChange('onSiteExpenseValue', newValue)}

                                                            totalHours={state.totalHours}
                                                            totalRemoteHours={state.totalRemoteHours}
                                                            totalOnSiteHours={state.totalOnSiteHours}

                                                            sumRates={state.sumRates}
                                                            sumExpenses={state.sumExpenses}
                                                            sumTotal={state.sumTotal}
                                                            setSumRates={(newValue) => handleChange('sumRates', newValue)}
                                                            setSumExpenses={(newValue) => handleChange('sumExpenses', newValue)}
                                                            setSumTotal={(newValue) => handleChange('sumTotal', newValue)}
                                                        />

                                                    </CardWithMinimize>
                                                </Grid>
                                            </Grid>

                                        </Show>



                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </Stack>
                </Card>
            </>
        );
    } catch (eachError) {

    }
}

export { CustomerJobEditComponent }